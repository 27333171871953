import IHeader from "components/Header/IHeader";

import styles from "components/Header/Header.module.scss";

export default function Header({ modeSelected }: IHeader) {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.header__title}>
          <h1>{modeSelected}</h1>
        </div>
      </header>
    </>
  );
}
