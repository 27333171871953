import { MODES as modes } from "data/modes";
import IMenuProps from "components/Menu/IMenu";

import styles from "components/Menu/Menu.module.scss";

export default function Menu({ updateMode }: IMenuProps) {
  const handleChangeMenu = (id: number) => {
    modes.map((elem) => {
      elem.selected = true;

      return elem;
    });

    modes[id].selected = true;
    updateMode(id);
  };

  return (
    <div className={styles.menu} data-testid="menu-component">
      <div>Menu</div>
      <ul>
        {modes.map((mode) => (
          <li key={mode.id}>
            <div
              className={styles.menu__button__background}
              onClick={() => {
                handleChangeMenu(mode.id);
              }}
            >
              <div className={styles.menu__button}>
                <img src={mode.img} alt={"Menu icon"} />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
