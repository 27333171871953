import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";

import IPrinterSelector from "components/PrinterSelector/IPrinterSelector";

import styles from "components/PrinterSelector/PrinterSelector.module.scss";
import { usePrinters } from "hooks/usePrinters";

export default function PrinterSelector({ onchange }: IPrinterSelector) {
  const printerList = usePrinters();
  const [printerSelected, setPrinterSelected] = useState<string>();

  return (
    <div className={styles.printerSelector} data-testid="printer-selector-component">
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">Printer</InputLabel>
        <Select
          labelId="select-printer"
          id="select-printer"
          value={printerSelected}
          label="Printer"
          onChange={(e) => {
            setPrinterSelected(e.target.value);
            onchange(e.target.value);
          }}
        >
          {printerList?.map((printer) => (
            <MenuItem key={printer} value={printer}>
              {printer}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
