import IGlaciereQR from "components/GlaciereQR/IGlaciereQR";
import qrCode from "img/qrCode.svg";

import styles from "components/GlaciereQR/GlaciereQR.module.scss";
import { Chip } from "@mui/material";

export default function GlaciereQR({ glaciereCode }: IGlaciereQR) {
  return (
    <div>
      <p className={styles.glaciereQR__instructions}>Scan your glaciere QR</p>
      <img src={qrCode} className={styles.glaciereQR__qrCode} alt="QR code" />
      {glaciereCode && (
        <div>
          <Chip label={glaciereCode} color="primary" className={styles.glaciereQR__chip} />
        </div>
      )}
    </div>
  );
}
