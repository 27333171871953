import { useEffect, useState } from "react";
import { Alert } from "@mui/material";

import Scanner from "components/Scanner/Scanner";
import { updateStatus } from "api/glaciereAPI";
import Loading from "pages/Loading/Loading";
import GlaciereQR from "components/GlaciereQR/GlaciereQR";

import styles from "styles/pages/BlockPage.module.scss";
import { getStatusKey, useStatus } from "hooks/useStatus";

export default function Obsolete() {
  const status = useStatus();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [glaciereCode, setGlaciereCode] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleScanedCode = (code: string) => {
    setGlaciereCode(null);
    setSuccess(false);
    if (code.indexOf("GLACIERE-") >= 0) {
      setGlaciereCode(code);
      setError(null);
    } else {
      setError(`Glaciere code is wrong. (Expected format GLACIERE-000999 but got ${code} )`);
    }
  };

  useEffect(() => {
    if (glaciereCode) {
      setIsLoading(true);

      const statusId = getStatusKey("OBSOLETE", status);

      updateStatus(glaciereCode, Number(statusId)).then((rep) => {
        if (rep?.status === 200 || rep?.status === 201) {
          setSuccess(true);
          setError(null);
          setGlaciereCode(null);
        } else {
          setError(rep.error);
        }
        setIsLoading(false);
      });
    }
  }, [glaciereCode, status]);

  return (
    <>
      {isLoading && <Loading />}
      <div className={styles.blocPage}>
        <GlaciereQR glaciereCode={glaciereCode} />
        {error && (
          <Alert
            severity="error"
            onClose={() => {
              setError(null);
            }}
          >
            <strong>{error}</strong>
          </Alert>
        )}
        {success && (
          <Alert
            severity="success"
            onClose={() => {
              setSuccess(false);
            }}
          >
            <strong>SUCCESS</strong>
          </Alert>
        )}
        <div className={styles.blocPage__scanner}>
          <Scanner onScan={handleScanedCode} />
        </div>
      </div>
    </>
  );
}
