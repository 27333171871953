import { IUser } from "data/user";
import { getProperty } from "utils/getProperty";

function getFromKey(key: string): string | null {
  const result = localStorage.getItem(key);

  return result;
}

function setKey(key: string, data: string): void {
  localStorage.setItem(key, data);
}

function removeKey(key: string): void {
  localStorage.removeItem(key);
}

function getElemFromKeyValue(key: string, propertyName: string): string {
  const elemFromKey = getFromKey(key);

  if (elemFromKey) {
    const parsedElem = JSON.parse(elemFromKey);

    return getProperty(parsedElem, propertyName);
  }

  return "";
}

function getUserParsedFromKey(): IUser {
  const userString = localStorageUtils.getFromKey("user");

  if (userString) {
    const foundUser = JSON.parse(userString);

    return foundUser;
  }

  return {
    loggedIn: false,
  } as IUser;
}

export const localStorageUtils = {
  getFromKey,
  setKey,
  removeKey,
  getElemFromKeyValue,
  getUserParsedFromKey,
};
