/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { getPrinters } from "api/glaciereAPI";

export const usePrinters = () => {
  const [printerList, setPrinterList] = useState<string[]>([]);

  useEffect(() => {
    getPrinters().then((rep) => {
      if (!rep?.error) {
        setPrinterList(rep.data);
      }
    });
  }, []);

  return printerList;
};
