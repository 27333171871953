import { useEffect, useState } from "react";

import { localStorageUtils } from "utils/localStorage";

import styles from "components/Footer/Footer.module.scss";
import packageJson from "../../../package.json";

export default function Footer() {
  const user = localStorageUtils.getUserParsedFromKey();
  const [nbClick, setNbClick] = useState<number>(0);

  useEffect(() => {
    if (nbClick >= 5 && !user.isDeveloper) {
      const userData = JSON.stringify({ ...user, isDeveloper: true });
      localStorageUtils.setKey("user", userData);

      window.location.reload();
    }
  }, [nbClick, user]);

  return (
    <>
      <div className={styles.footer__version} onClick={() => setNbClick(nbClick + 1)}>
        Version {packageJson.version}
      </div>
    </>
  );
}
