import { useEffect, useState } from "react";
import { Alert } from "@mui/material";

import { dissociateShipment } from "api/glaciereAPI";
import Scanner from "components/Scanner/Scanner";
import Loading from "pages/Loading/Loading";
import GlaciereQR from "components/GlaciereQR/GlaciereQR";
import ShipmentLabel from "components/ShipmentLabel/ShipmentLabel";

import styles from "styles/pages/BlockPage.module.scss";

export default function Dissociate() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [shipLabel, setShipLabel] = useState<string | null>(null);
  const [glaciereCode, setGlaciereCode] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const handleScanedCode = (code: string) => {
    setError(null);
    setSuccess(false);
    setGlaciereCode(null);
    if (code.indexOf("GLACIERE-") >= 0 || code.indexOf("glaciere-") >= 0) {
      setGlaciereCode(code);
    } else if (code.length === 14) {
      setShipLabel(code);
    } else {
      setError(`Code scanned is wrong. (${code})`);
    }
  };

  useEffect(() => {
    if (shipLabel && glaciereCode) {
      setIsLoading(true);

      dissociateShipment(glaciereCode, shipLabel).then((rep) => {
        if (rep?.status === 200 || rep?.status === 201) {
          setSuccess(true);
          setError(null);
          setShipLabel(null);
          setGlaciereCode(null);
        } else {
          setError(rep.error);
        }
        setIsLoading(false);
      });
    }
  }, [shipLabel, glaciereCode]);

  return (
    <>
      {isLoading && <Loading />}
      <div className={styles.blocPage} data-testid="dissociate-page">
        <ShipmentLabel shipmentLabel={shipLabel} />
        <GlaciereQR glaciereCode={glaciereCode} />
        {error && (
          <Alert
            severity="error"
            onClose={() => {
              setError(null);
            }}
          >
            <strong>{error}</strong>
          </Alert>
        )}
        {success && (
          <Alert
            severity="success"
            onClose={() => {
              setSuccess(false);
            }}
          >
            <strong>SUCCESS</strong>
          </Alert>
        )}
        <div className={styles.blocPage__scanner}>
          <Scanner onScan={handleScanedCode} />
        </div>
      </div>
    </>
  );
}
