/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { getStatus } from "api/glaciereAPI";

export const useStatus = () => {
  const [statusList, setStatusList] = useState(new Map<number, string>());

  useEffect(() => {
    getStatus().then((rep) => {
      if (!rep?.error) {
        const statusMap = new Map<number, string>();
        Object.keys(rep.data)
          .map((k) => statusMap.set(rep.data[k], k))
          .sort();
        // @ts-ignore
        const ascMapKeys = new Map<number, string>([...statusMap.entries()].sort((a, b) => a[0] - b[0]));
        setStatusList(ascMapKeys);
      }
    });
  }, []);

  return statusList;
};

export const getStatusKey = (label: string, status: Map<number, string>) => {
  const entries = Array.from(status.entries());
  const foundEntry = entries.find(([, val]) => val === label);

  if (foundEntry) {
    return foundEntry[0]; // The first element of the entry is the key
  }

  return undefined; // Value not found in the Map
};

export const getStatusLabel = (label: string | undefined) => (label != null ? label : "UNKNOWN");
