import { useEffect, useState } from "react";
import { Alert } from "@mui/material";

import Scanner from "components/Scanner/Scanner";
import { getGlaciereById } from "api/glaciereAPI";
import Loading from "pages/Loading/Loading";
import { IGlaciere } from "data/glaciere";
import { getStatusLabel, useStatus } from "hooks/useStatus";
import GlaciereQR from "components/GlaciereQR/GlaciereQR";

import styles from "styles/pages/BlockPage.module.scss";

export default function Status() {
  const status = useStatus();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [glaciereCode, setGlaciereCode] = useState<string | null>(null);
  const [glaciere, setGlaciere] = useState<IGlaciere | null>(null);

  const [error, setError] = useState<string | null>(null);

  const handleScanedCode = (code: string) => {
    setGlaciereCode(null);
    if (code.indexOf("GLACIERE-") >= 0) {
      setGlaciereCode(code);
      setError(null);
    } else {
      setError(`Glaciere code is wrong. (Expected format GLACIERE-000999 but got ${code} )`);
    }
  };

  useEffect(() => {
    if (glaciereCode) {
      setIsLoading(true);
      setGlaciere(null);

      getGlaciereById(glaciereCode).then((rep) => {
        if (!rep?.error && rep.length === 1) {
          setGlaciere(rep[0]);
          setGlaciereCode(null);
          setError(null);
        } else if (rep?.error && rep.error === 503) {
          setError(rep.error);
        } else {
          setError("Glaciere not found.");
        }
      });

      setIsLoading(false);
    }
  }, [glaciereCode]);

  return (
    <>
      {isLoading && <Loading />}
      <div className={styles.blocPage}>
        <GlaciereQR glaciereCode={glaciereCode} />
        {error && (
          <Alert
            severity="error"
            onClose={() => {
              setError(null);
            }}
          >
            <strong>{error}</strong>
          </Alert>
        )}
        {glaciere && (
          <Alert severity="info">
            <strong>
              {glaciere.glaciereId} has status {getStatusLabel(status.get(glaciere.statusId))}
            </strong>
          </Alert>
        )}

        <div className={styles.blocPage__scanner}>
          <Scanner onScan={handleScanedCode} />
        </div>
      </div>
    </>
  );
}
