import { Chip } from "@mui/material";

import IShipmentLabel from "components/ShipmentLabel/IShipmentLabel";
import barCode from "img/barCode.png";

import styles from "components/ShipmentLabel/ShipmentLabel.module.scss";

export default function ShipmentLabel({ shipmentLabel }: IShipmentLabel) {
  return (
    <div>
      <p className={styles.shipmentLabel__instructions}>Scan your shipment label</p>
      <img src={barCode} className={styles.shipmentLabel__barCode} alt="QR code" />
      {shipmentLabel && (
        <div>
          <Chip label={shipmentLabel} color="primary" />
        </div>
      )}
    </div>
  );
}
