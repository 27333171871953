import { Blocks } from "react-loader-spinner";

import styles from "styles/pages/Loading.module.scss";

export default function Loading() {
  return (
    <>
      <div className={styles.loading}>
        <div className={styles.loading__spinner}>
          <Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />
        </div>
      </div>
    </>
  );
}
