import Login from "pages/Login/Login";
import Home from "pages/Home/Home";
import Footer from "components/Footer/Footer";
import { localStorageUtils } from "utils/localStorage";
import "./styles/global.scss";

function App() {
  const user = localStorageUtils.getUserParsedFromKey();

  return (
    <div className="App">
      {user.loggedIn ? <Home /> : <Login />}
      <Footer />
    </div>
  );
}

export default App;
