import { useEffect, useRef, useState } from "react";

import { localStorageUtils } from "utils/localStorage";
import IScannerProps from "components/Scanner/IScanner";

import styles from "components/Scanner/Scanner.module.scss";

export default function Scanner({ onScan }: IScannerProps) {
  const user = localStorageUtils.getUserParsedFromKey();
  const [text, setText] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleScan(text);
      inputRef.current?.blur();
    }
  };

  const handleScan = (txt: string) => {
    onScan(txt);
    setText("");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      inputRef.current?.focus();
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div data-testid="scanner-component">
      {user.isDeveloper ? (
        <div className={styles.scanner}>
          <div className={styles.scanner__title}>DEV TOOL SCANNER</div>
          <div>
            <input type={"text"} onChange={(e) => setText(e.target.value)} placeholder={"bar/QR code"} value={text} />
          </div>
          <button onClick={() => handleScan(text)}>Scan</button>
        </div>
      ) : (
        <div className={styles.scanner_input_hidden}>
          <input
            ref={inputRef}
            autoFocus={false}
            onKeyDown={handleKeyDown}
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
        </div>
      )}
    </div>
  );
}