import icon1 from "img/menu1.svg";
import icon2 from "img/menu2.svg";
import icon3 from "img/menu3.svg";
import icon4 from "img/menu4.svg";
import icon5 from "img/menu5.svg";
import icon6 from "img/menu6.svg";
import icon7 from "img/qrCode.svg";

export interface ModeProps {
  id: number;
  title: string;
  img: string;
  selected: boolean;
}

export const MODES: ModeProps[] = [
  { id: 0, title: "Get status", img: icon7, selected: true },
  { id: 1, title: "Associate end of line", img: icon1, selected: false },
  { id: 2, title: "Reception", img: icon2, selected: false },
  { id: 3, title: "Dishwasher", img: icon3, selected: false },
  { id: 4, title: "Reprint", img: icon4, selected: false },
  { id: 5, title: "Obsolete", img: icon5, selected: false },
  { id: 6, title: "Dissociate", img: icon6, selected: false },
];
