import React from "react";
import { Container } from "@mui/material";

import Menu from "components/Menu/Menu";
import { MODES as modes } from "data/modes";
import EndOfLine from "pages/EndOfLine/EndOfLine";
import Warehouse from "pages/Warehouse/Warehouse";
import Dishwasher from "pages/Dishwasher/Dishwasher";
import Reprint from "pages/Reprint/Reprint";
import Obsolete from "pages/Obsolete/Obsolete";
import Header from "components/Header/Header";
import Dissociate from "pages/Dissociate/Dissociate";
import Status from "pages/Status/Status";

import styles from "pages/Home/Home.module.scss";

export default function Home() {
  const [modeSelected, setModeSelected] = React.useState<number>(0);

  return (
    <>
      <div className={styles.home}>
        <div className={styles.home__container}>
          <div className={styles.home__menu}>
            <Menu updateMode={setModeSelected} />
          </div>
          <div className={styles.home__page}>
            <Container maxWidth={"sm"}>
              <Header modeSelected={modes[modeSelected].title} />
              <div>
                {modeSelected === 0 && <Status />}
                {modeSelected === 1 && <EndOfLine />}
                {modeSelected === 2 && <Warehouse />}
                {modeSelected === 3 && <Dishwasher />}
                {modeSelected === 4 && <Reprint />}
                {modeSelected === 5 && <Obsolete />}
                {modeSelected === 6 && <Dissociate />}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
