/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from "axios";
import { getEnvVar } from "../lib/GetEnvVar";

// @ts-ignore
// const base_url = window["getConfig"].REACT_APP_BACK_URL;
const BASE_URL = getEnvVar("REACT_APP_BACK_URL");

interface GlaciereRequest {
  glaciereId: string;
  statusId?: number;
  fulfillmentId?: string;
  printerId?: string;
  pickup_type?: number;
}

export async function getGlaciereById(glaciereId: string) {
  try {
    const resp = await axios.post(
      `${BASE_URL}/api/glaciere/researches`,
      JSON.stringify({
        glaciereId: glaciereId,
      }),
    );

    if (resp.status === 200) {
      return resp.data;
    }

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function updateStatus(glaciereId: string, statusId: number) {
  const req: GlaciereRequest = { glaciereId: glaciereId, statusId: statusId, pickup_type: 1 };

  try {
    const resp = await axios.put(`${BASE_URL}/api/glaciere`, JSON.stringify(req));
    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function associateShipment(glaciereId: string, shipmentId?: string, printer?: string) {
  const req: GlaciereRequest = { glaciereId: glaciereId };

  if (shipmentId) {
    req.fulfillmentId = shipmentId;
  }

  if (printer) {
    req.printerId = printer;
  }

  try {
    const resp = await axios.put(`${BASE_URL}/api/glaciere/associateShipment`, JSON.stringify(req));

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function dissociateShipment(glaciereId: string, shipmentId?: string) {
  const req: GlaciereRequest = { glaciereId: glaciereId };

  if (shipmentId) {
    req.fulfillmentId = shipmentId;
  }

  try {
    const resp = await axios.put(`${BASE_URL}/api/glaciere/dissociateShipment`, JSON.stringify(req));

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function printLabel(glaciereId: string, printer?: string) {
  const req = { glaciereId: glaciereId, printerId: printer };

  try {
    const resp = await axios.post(`${BASE_URL}/api/glaciere/print`, JSON.stringify(req));

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function getStatus() {
  try {
    const resp = await axios.get(`${BASE_URL}/api/glaciere/status`);

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

export async function getPrinters() {
  try {
    const resp = await axios.get(`${BASE_URL}/api/printers`);

    return handleResponse(resp);
  } catch (error: any) {
    if (error?.response?.data?.error) {
      return handleResponse({ status: error?.response?.status, error: error?.response?.data?.error });
    }
    return handleResponse({ status: 503 });
  }
}

function handleResponse(resp: any) {
  if (resp.status === 200 || resp.status === 201) {
    return { status: resp.status, data: resp.data };
  } else if (resp.status === 503) {
    return {
      error:
        "An error occured when contacting Glaciere API. Please try again in few minutes or contact your administrator.",
    };
  } else {
    return resp;
  }
}
